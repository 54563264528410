import React from 'react'
import ArchiveWrapper from '../../../components/ArchiveWrapper/ArchiveWrapper'
import { graphql } from 'gatsby'
import StyledGallery from '../../../components/StyledGallery/StyledGallery'
import ReturnToPrevPage from '../../../components/ReturnToPrevPage/ReturnToPrevPage'
import Img from 'gatsby-image'
import MonumentContentWrapper from '../../../components/MonumentContentWrapper/MonumentContentWrapper'
import { Header1 } from '../../../components/Headers/Headers'
import SEO from '../../../components/seo'
import VideoPlayer from '../../../components/VideoPlayer/VideoPlayer'

const OsiemdziesiecioLecieIIWSJakubow = ({ data }) => {
  const images = data.allFile.edges.map(({ node }) => node.childImageSharp)
  return (
    <ArchiveWrapper>
      <SEO title="Jakubów, 80 lecie wybuchu II Wojny Światowej" />
      <ReturnToPrevPage to={'/gminy/jakubow'}>
        Materiały dotyczące gminy Jakubów
      </ReturnToPrevPage>
      <Header1>Jakubów, 80 lecie wybuchu II Wojny Światowej</Header1>
      <Img fluid={data.file.childImageSharp.fluid} />
      <MonumentContentWrapper>
        <p>
          Po prawej stronie drogi na Mińsk Mazowiecki mieści się pomnik 80 lecia
          wybuchu II Wojny Światowej. Pielęgnuje on pamięć o ofiarach
          mieszkańcach powiatu mińskiego, którzy zginęli z rąk najeźdźców z III
          Rzeszy Niemieckiej i Związku Sowieckiego. Erygowany 1 września 2019
          roku z inicjatywy Muzeum Ziemi Mińskiej.
        </p>
      </MonumentContentWrapper>
      <StyledGallery images={images} />
    </ArchiveWrapper>
  )
}

export const query = graphql`
  {
    file(
      name: { regex: "/featured/" }
      relativePath: { regex: "/jakubow/80leciews/" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    allFile(filter: { relativePath: { regex: "/jakubow/80leciews/" } }) {
      edges {
        node {
          childImageSharp {
            thumb: fluid(maxWidth: 270, maxHeight: 270) {
              ...GatsbyImageSharpFluid
            }
            full: fluid(maxWidth: 1024) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`

export default OsiemdziesiecioLecieIIWSJakubow
